<i18n>
ru:
  minMods: нужно выбрать как минимум
  maxMods: можно выбрать максимум
  minModsRequirementsNotMet: 'В категории «{category}» необходимо выбрать как минимум {min} модификаторов!'
  minModsRequirementsNotMetFromTwoToFour: 'В категории «{category}» необходимо выбрать как минимум {min} модификатора!'
  minModsRequirementsNotMetOne: 'В категории «{category}» необходимо выбрать как минимум один модификатор!'
  modRemove: убрать
  modWithout: 'без '
  numMax: 'Максимум этого продукта на корзину: {max} шт.'
  recommended: Рекомендуемые
  totalMods: 'Стоимость модификаторов: '
  totalPay: 'Стоимость: '
  totalPayWithDiscount: 'Стоимость с скидкой: '
  unavailableMessage: Продукт на данный момент недоступен.
ua:
  minMods: нужно выбрать как минимум
  maxMods: можна вибрати максимум
  minModsRequirementsNotMet: 'У категорії «{category}» необхідно вибрати як мінімум {min} модифікаторів!'
  minModsRequirementsNotMetFromTwoToFour: 'У категорії «{category}» необхідно вибрати як мінімум {min} модифікатора!'
  minModsRequirementsNotMetOne: 'У категорії «{category}» необхідно вибрати як мінімум один модифікатор!'
  modRemove: прибрати
  modWithout: 'без '
  numMax: 'Максимум цього продукту на кошик: {max} шт.'
  recommended: Рекомендований
  totalMods: 'Вартість модифікаторів: '
  totalPay: 'Вартість: '
  totalPayWithDiscount: 'Вартість зі знижкою: '
  unavailableMessage: Продукт на даний момент недоступний.
us:
  minMods: you need to pick at least
  maxMods: you can choose no more than
  minModsRequirementsNotMet: 'You need to select {min} modifiers for «{category}» category!'
  minModsRequirementsNotMetFromTwoToFour: 'You need to select {min} modifiers for «{category}» category!'
  minModsRequirementsNotMetOne: 'You need to select one modifier for «{category}» category!'
  modRemove: remove
  modWithout: 'without '
  numMax: 'Maximum amount of this product is : {max} piece(s).'
  recommended: Recommended
  totalMods: 'Cost of modifiers: '
  totalPay: 'Cost: '
  totalPayWithDiscount: 'Discounted price: '
  unavailableMessage: This product is unavailable at the moment.
</i18n>

<template>
  <div
    v-if="menuConstructor && selectedOption"
    class="v-constructor"
    :class="{
      'v-constructor-full-page-view': !fromPopup,
      'v-constructor-take-half': appConfig.VueSettingsPreRun.ConstructorPagePhotoTakeHalf,
      'v-constructor-square-photo': !appConfig.VueSettingsPreRun.ConstructorPagePhotoTakeHalf,
      'v-constructor-full-page-view-no-mod': small
    }"
    data-test-id="product-constructor"
  >
    <div
      itemscope
      :class="{
        'v-constructor-no-right': small,
        'v-constructor-left-part': !small
      }"
      data-test-id="product-constructor-left-part"
      itemtype="http://schema.org/ImageObject"
    >
      <div class="v-constructor-image-block">
        <common-product-image-zoomable
          :alt="menuConstructor.Name"
          :image="menuConstructor.OriginalImage"
        />
      </div>
      <menu-discount-timers-single
        v-if="discountApplicable"
        :on-timer-end="checkDiscountTimers"
        :product-id="menuConstructor.ID"
      />
      <div class="v-mb-sm">
        <h1
          class="v-constructor-name"
          itemprop="name"
          v-html="sanitize(constructorInfo?.Name)"
        />
        <common-quantity-measure
          v-if="selectedOption.Quantity > 0"
          :measure="selectedOption.Measure"
          :quantity="selectedOption.Quantity"
        />
        <common-quantity-measure
          v-else-if="selectedOption.Weight > 0"
          :quantity="selectedOption.Weight"
        />
      </div>
      <!--#region PersonalProductMessage-->
      <div
        v-if="(constructorInfo?.PersonalProductMessage ?? '').length > 0"
        class="v-constructor-personal-product-message v-mb-sm"
      >
        <icon-menu-info /> <span v-html="menuConstructor.PersonalProductMessage" />
      </div>
      <!--#endregion-->
      <!--#region NoPickupOrDeliveryReason-->
      <div
        v-if="(menuConstructor.NoOrderTypeReasonText ?? '').length > 0"
        class="v-constructor-personal-product-message v-mb-sm"
      >
        <icon-menu-info /> <span v-html="menuConstructor.NoOrderTypeReasonText" />
      </div>
      <!--#endregion-->

      <div
        v-if="isTextDescription"
        class="v-constructor-desc v-mb-sm"
        itemprop="description"
        v-html="descriptionWithOptionsSanitized"
      />
      <menu-description
        v-else-if="
          !(isExtraSmall && menuConstructor.DescriptionType === ProductDescriptionType.Ingredients)
        "
        is-popup-or-full-page
        :product-in-list="menuConstructor"
      />
      <!--#region caloric-->
      <menu-caloric
        hide-weight
        :caloric-product="selectedOption"
        :in-one-line="!!isExtraSmall"
      />
      <!--#endregion-->

      <menu-description
        v-if="isExtraSmall && menuConstructor.DescriptionType === ProductDescriptionType.Ingredients"
        is-popup-or-full-page
        :product-in-list="menuConstructor"
      />

      <menu-products-containing-product
        :product-id="menuConstructor.ID"
        :product-name="menuConstructor.Name"
      />
    </div>

    <div
      :class="{
        'v-constructor-no-right v-px-sm': small,
        'v-constructor-right-part': !small
      }"
      data-test-id="product-constructor-right-part"
    >
      <div
        v-if="menuConstructor.Options && objectKeys(menuConstructor.Options).length > 0"
        class="v-constructor-options"
        :class="{
          'v-dark': menuStore.CurrentGroup?.UseAlternateTheme
            ? appConfig.VueSettingsPreRun.Theme.AlternativeTheme.DarkTheme
            : appConfig.VueSettingsPreRun.Theme.DefaultTheme.DarkTheme
        }"
        data-test-id="product-option"
      >
        <menu-option-selector
          :options="menuConstructor.Options"
          :product="menuConstructor"
          :threshold="threshold"
          data-test-id="product-constructor"
        />
      </div>
      <div
        v-if="!small"
        class="v-scroll-box v-scrollbar"
      >
        <template v-if="menuConstructor.Active && !menuConstructor.InStopList">
          <div
            v-for="(category, index) in constructorCategories"
            :key="`constructor-categories${index}`"
            class="v-constructor-category"
          >
            <div
              v-if="getAvailableModsPerCategory(category).length > 0"
              class="v-mb-sm"
            >
              <template v-if="getAvailableModsPerCategory(category).length > 0">
                <hr
                  v-if="
                    appConfig.VueSettingsPreRun.ConstructorPageShowHorizontalLineCategories && index > 0
                  "
                />
                <div
                  v-if="
                    (appConfig.VueSettingsPreRun.ConstructorPageShowRemainingMods === 'required' &&
                      isCategoryRequired(category)) ||
                    appConfig.VueSettingsPreRun.ConstructorPageShowRemainingMods === 'all'
                  "
                  class="v-d-flex v-justify-content-between v-mb-sm v-constructor-category-name"
                  data-test-id="product-constructor-category-name"
                >
                  <div v-html="sanitize(category.Name)" />

                  <div
                    class="d-flex v-flex-row v-constructor-category-mod-counter"
                    :class="{ 'v-error-color': isCategoryRequired(category) }"
                    data-test-id="product-constructor-category-mod-counter"
                  >
                    {{ selectedModsByCategory[category.ID] }}
                    / {{ modLimitPerCategory[category.ID] }}

                    <div
                      v-if="(category.IngredientsMax ?? 0) > 0 || (category.IngredientsMin ?? 0) > 0"
                      class="v-help-block-icon"
                    >
                      <common-popover>
                        <template #content>
                          <span
                            v-if="(category.IngredientsMin ?? 0) > 0 && isCategoryRequired(category)"
                            v-html="`${translate('cardPage.minMods')} ${category.IngredientsMin}`"
                          />
                          <span
                            v-else-if="(category.IngredientsMax ?? 0) > 0"
                            v-html="`${translate('cardPage.maxMods')} ${category.IngredientsMax}`"
                          />
                        </template>
                      </common-popover>
                    </div>
                  </div>
                </div>
                <span
                  v-else
                  class="v-constructor-category-name v-pb-sm"
                  data-test-id="product-constructor-category-name"
                  v-html="sanitize(category.Name)"
                />
              </template>
            </div>
            <div
              class="v-constructor-category-mods-wrapper"
              data-test-id="product-constructor-mods-wrapper"
            >
              <!--#region if max value is 1-->
              <div
                v-for="mod in getAvailableModsPerCategory(category).filter((m) => m.ValueMax === 1)"
                :key="`constructor-cat-${category.ID}-mod-${mod.ID}`"
                class="v-constructor-category-mod v-pb-sm"
                :class="{
                  'v-constructor-category-mod-four-columns':
                    appConfig.VueSettingsPreRun.ConstructorPageModsOneLayout === 'four-columns',
                  'v-constructor-category-mod-three-columns':
                    appConfig.VueSettingsPreRun.ConstructorPageModsOneLayout === 'three-columns',
                  'v-constructor-category-mod-two-columns':
                    appConfig.VueSettingsPreRun.ConstructorPageModsOneLayout === 'two-columns',
                  'v-constructor-category-mod-columns':
                    appConfig.VueSettingsPreRun.ConstructorPageModsOneLayout === 'columns',
                  'v-constructor-category-mod-row':
                    appConfig.VueSettingsPreRun.ConstructorPageModsOneLayout === 'row',
                  disabled: menuStore.ModifiersInStopList.includes(mod.ID)
                }"
                data-test-id="product-constructor-mod-single--solo"
              >
                <template v-if="appConfig.VueSettingsPreRun.ConstructorPageModsOneType === 'pic'">
                  <div
                    class="v-mod-box-shadow"
                    :class="{
                      'v-dark': menuStore.CurrentGroup?.UseAlternateTheme
                        ? appConfig.VueSettingsPreRun.Theme.AlternativeTheme.DarkTheme
                        : appConfig.VueSettingsPreRun.Theme.DefaultTheme.DarkTheme,
                      disable: isModDisable(category, mod)
                    }"
                  >
                    <common-badge-arithmetic
                      :data-test-id="`product-constructor-category-mod-badge${
                        isModDisable(category, mod) ? '--disabled' : ''
                      }`"
                      :content="modsByCategory[mod.CategoryID][mod.ID]"
                      :on-click="() => clickOnBadge(category, mod)"
                    >
                      <div class="v-image-label">
                        <img
                          v-if="showImage && mod.SmallImage"
                          class="v-constructor-category-mod-img-big v-img-fluid"
                          :class="{
                            'v-constructor-category-mod-img-big-selected':
                              modsByCategory[mod.CategoryID][mod.ID] > 0
                          }"
                          :alt="mod.Name"
                          :onclick="() => clickOnBadge(category, mod)"
                          :src="mod.SmallImage.Domain + mod.SmallImage.Path"
                          loading="lazy"
                        />
                        <transition
                          appear
                          mode="out-in"
                          name="fade"
                        >
                          <icon-general-ban
                            v-if="
                              (mod.Name.toLowerCase().includes(
                                translate('cardPage.modRemove').toLowerCase()
                              ) ||
                                mod.Name.toLowerCase().includes(
                                  translate('cardPage.modWithout').toLowerCase()
                                )) &&
                              modsByCategory[mod.CategoryID][mod.ID] > 0
                            "
                            class="v-constructor-category-mod-img-icon-ban"
                            :onclick="() => clickOnBadge(category, mod)"
                          />
                          <icon-general-check
                            v-else-if="modsByCategory[mod.CategoryID][mod.ID] > 0"
                            class="v-constructor-category-mod-img-icon"
                            :onclick="() => clickOnBadge(category, mod)"
                          />
                        </transition>
                      </div>
                      <div class="v-constructor-category-mod-title">
                        <div class="v-mod-name">
                          <div
                            class="v-mb-xs"
                            v-html="sanitize(mod.Name)"
                          />
                          <div
                            v-if="
                              appConfig.VueSettingsPreRun.ConstructorPageShowModDescription &&
                              mod.Description?.length > 0
                            "
                            class="v-help-block-icon"
                          >
                            <common-popover>
                              <template #content>
                                <span v-html="sanitize(mod.Description)" />
                              </template>
                            </common-popover>
                          </div>
                        </div>
                        <div
                          v-if="
                            mod.Price > 0 ||
                            appConfig.VueSettingsPreRun.ConstructorPageModPriceAlwaysShow
                          "
                          class="v-mod-bubble"
                        >
                          +<common-currency :amount="mod.Price" />
                        </div>
                      </div>
                    </common-badge-arithmetic>
                  </div>
                </template>
                <template v-else>
                  <div class="v-constructor-category-mod-name">
                    <input
                      v-if="categoryOnlyOneRequiredMod(category)"
                      :id="mod.ID"
                      class="v-input-radio"
                      :checked="modsByCategory[mod.CategoryID][mod.ID] === 1"
                      :value="mod.ID"
                      type="radio"
                      @click="radioSelect(category, mod)"
                    />
                    <input
                      v-else
                      :id="mod.ID"
                      class="v-input-checkbox"
                      :disabled="
                        category.EnableMaxLimit &&
                        numberOfAvailableModsByCategory[mod.CategoryID] === 0 &&
                        modsByCategory[mod.CategoryID][mod.ID] === 0
                      "
                      :checked="modsByCategory[mod.CategoryID][mod.ID] === 1"
                      :value="modsByCategory[mod.CategoryID][mod.ID] === 1"
                      type="checkbox"
                      @input="(event: Event) => checkboxSelect(event, mod)"
                    />
                    <label
                      v-if="categoryOnlyOneMod(category)"
                      :data-test-id="`product-constructor-category-mod-label${
                        category.EnableMaxLimit &&
                        numberOfAvailableModsByCategory[mod.CategoryID] === 0 &&
                        modsByCategory[mod.CategoryID][mod.ID] === 0
                          ? '--disabled'
                          : ''
                      }`"
                      :for="mod.ID"
                    >
                      <span class="v-constructor-category-mod-img">
                        <arora-nuxt-image
                          v-if="showImage && mod.SmallImage"
                          :alt="mod.Name"
                          :image="mod.SmallImage"
                          image-type="ProductSmall"
                        />
                      </span>
                      <span v-html="mod.Name" />
                      <span class="v-mod-name-price">
                        <span
                          v-if="
                            mod.Price > 0 ||
                            appConfig.VueSettingsPreRun.ConstructorPageModPriceAlwaysShow
                          "
                          class="v-mod-bubble"
                        >
                          + <common-currency :amount="mod.Price" />
                        </span>
                      </span>
                    </label>
                    <label
                      v-else
                      :data-test-id="`product-constructor-category-mod-label${
                        category.EnableMaxLimit &&
                        numberOfAvailableModsByCategory[mod.CategoryID] === 0 &&
                        modsByCategory[mod.CategoryID][mod.ID] === 0
                          ? '--disabled'
                          : ''
                      }`"
                      :for="mod.ID"
                    >
                      <div
                        class="v-image-label"
                        :class="{
                          'v-d-flex v-w-100':
                            appConfig.VueSettingsPreRun.ConstructorPageModsOneLayout ===
                              'four-columns' ||
                            appConfig.VueSettingsPreRun.ConstructorPageModsOneLayout === 'three-columns'
                        }"
                      >
                        <span class="v-constructor-category-mod-img v-w-100">
                          <arora-nuxt-image
                            v-if="showImage && mod.SmallImage"
                            :alt="mod.Name"
                            :image="mod.SmallImage"
                            image-type="ProductSmall"
                          />
                        </span>
                      </div>
                      <span
                        :class="{
                          'v-w-100 v-text-center':
                            appConfig.VueSettingsPreRun.ConstructorPageModsOneLayout ===
                              'four-columns' ||
                            appConfig.VueSettingsPreRun.ConstructorPageModsOneLayout === 'three-columns'
                        }"
                        v-html="mod.Name"
                      />
                      <span class="v-mod-name-price">
                        <span
                          v-if="
                            mod.Price > 0 ||
                            appConfig.VueSettingsPreRun.ConstructorPageModPriceAlwaysShow
                          "
                          class="v-mod-bubble"
                        >
                          + <common-currency :amount="mod.Price" />
                        </span>
                      </span>
                    </label>
                  </div>
                </template>
              </div>

              <!--#endregion -->

              <!--#region if max value is more than 1 -->
              <div
                v-for="mod in getAvailableModsPerCategory(category).filter((m) => m.ValueMax > 1)"
                :key="`constructor-cat-${category.ID}-mod-${mod.ID}`"
                class="v-constructor-category-mod v-mb-sm"
                :class="{
                  'v-constructor-category-mod-four-columns':
                    appConfig.VueSettingsPreRun.ConstructorPageModsLayout === 'four-columns',
                  'v-constructor-category-mod-three-columns':
                    appConfig.VueSettingsPreRun.ConstructorPageModsLayout === 'three-columns',
                  'v-constructor-category-mod-two-columns':
                    appConfig.VueSettingsPreRun.ConstructorPageModsLayout === 'two-columns',
                  'v-constructor-category-mod-columns':
                    appConfig.VueSettingsPreRun.ConstructorPageModsLayout === 'columns',
                  'v-constructor-category-mod-row':
                    appConfig.VueSettingsPreRun.ConstructorPageModsLayout === 'row',
                  disabled: menuStore.ModifiersInStopList.includes(mod.ID)
                }"
                data-test-id="product-constructor-mod-single"
              >
                <!--#region pic-->
                <div
                  class="v-mod-box-shadow v-d-flex v-flex-row v-align-items-center v-justify-content-center"
                  :class="{
                    'v-dark': menuStore.CurrentGroup?.UseAlternateTheme
                      ? appConfig.VueSettingsPreRun.Theme.AlternativeTheme.DarkTheme
                      : appConfig.VueSettingsPreRun.Theme.DefaultTheme.DarkTheme,
                    'v-flex-wrap-initial': appConfig.VueSettingsPreRun.ConstructorPageModsType === 'pic'
                  }"
                >
                  <template
                    v-if="
                      appConfig.VueSettingsPreRun.ConstructorPageModsType === 'pic' &&
                      appConfig.VueSettingsPreRun.ConstructorPageModsLayout === 'two-columns'
                    "
                  >
                    <div class="v-pic-two-columns">
                      <img
                        v-if="showImage && mod.SmallImage"
                        class="v-constructor-category-mod-img-big v-img-fluid v-mb-sm"
                        :class="{
                          'v-constructor-category-mod-img-big-selected':
                            modsByCategory[mod.CategoryID][mod.ID] > 0
                        }"
                        :alt="mod.Name"
                        :src="mod.SmallImage.Domain + mod.SmallImage.Path"
                        loading="lazy"
                      />
                      <div class="v-constructor-category-mod-title">
                        <span v-html="mod.Name" />
                        <div
                          v-if="
                            mod.Price > 0 ||
                            appConfig.VueSettingsPreRun.ConstructorPageModPriceAlwaysShow
                          "
                          class="v-mod-bubble"
                        >
                          +<common-currency :amount="mod.Price" />
                        </div>
                      </div>
                      <arora-stepper
                        :disable-plus-button="
                          modsByCategory[mod.CategoryID][mod.ID] >= mod.ValueMax ||
                          numberOfAvailableModsByCategory[mod.CategoryID] <= 0
                        "
                        :disabled="
                          numberOfAvailableModsByCategory[mod.CategoryID] <= 0 &&
                          modsByCategory[mod.CategoryID][mod.ID] === 0
                        "
                        :data-test-id="`product-constructor-stepper-${mod.ID}`"
                        :label="translate('addToCartButton.addToCart')"
                        :on-decrement="() => updateModCount(mod.CategoryID, mod.ID, false)"
                        :on-increment="() => updateModCount(mod.CategoryID, mod.ID, true)"
                        :show-minus-on-zero="appConfig.VueSettingsPreRun.ConstructorPageModsShowMinus"
                        :value="modsByCategory[mod.CategoryID][mod.ID]"
                      />
                    </div>
                  </template>
                  <template v-else-if="appConfig.VueSettingsPreRun.ConstructorPageModsType === 'pic'">
                    <common-badge-arithmetic
                      :data-test-id="`product-constructor-category-mod-badge${
                        numberOfAvailableModsByCategory[mod.CategoryID] <= 0 &&
                        modsByCategory[mod.CategoryID][mod.ID] === 0
                          ? '--disabled'
                          : ''
                      }`"
                      :disabled="
                        numberOfAvailableModsByCategory[mod.CategoryID] <= 0 &&
                        modsByCategory[mod.CategoryID][mod.ID] === 0
                      "
                      :content="modsByCategory[mod.CategoryID][mod.ID]"
                      :on-click="() => clickOnBadge(category, mod)"
                    >
                      <img
                        v-if="showImage && mod.SmallImage"
                        class="v-constructor-category-mod-img-big v-img-fluid"
                        :class="{
                          'v-constructor-category-mod-img-big-selected':
                            modsByCategory[mod.CategoryID][mod.ID] > 0
                        }"
                        :alt="mod.Name"
                        :onclick="() => updateModCount(mod.CategoryID, mod.ID, true)"
                        :src="mod.SmallImage.Domain + mod.SmallImage.Path"
                        loading="lazy"
                      />
                      <transition
                        appear
                        mode="out-in"
                        name="fade"
                      >
                        <i
                          v-if="modsByCategory[mod.CategoryID][mod.ID] > 0"
                          class="v-constructor-category-mod-img-icon"
                          :onclick="() => updateModCount(mod.CategoryID, mod.ID, true)"
                          data-test-id="product-constructor-category-mod-img-icon"
                          >{{ modsByCategory[mod.CategoryID][mod.ID] }}</i
                        >
                      </transition>
                      <div class="v-constructor-category-mod-title">
                        <span v-html="mod.Name" />
                        <div
                          v-if="
                            mod.Price > 0 ||
                            appConfig.VueSettingsPreRun.ConstructorPageModPriceAlwaysShow
                          "
                          class="v-mod-bubble"
                        >
                          +<common-currency :amount="mod.Price" />
                        </div>
                      </div>
                    </common-badge-arithmetic>
                  </template>
                  <!--#endregion-->
                  <template v-else>
                    <div class="v-image-label v-flex-100">
                      <div class="v-constructor-category-mod-img">
                        <arora-nuxt-image
                          v-if="showImage && mod.SmallImage"
                          :alt="mod.Name"
                          :image="mod.SmallImage"
                          image-type="ProductSmall"
                        />
                        <icon-general-ban
                          v-if="
                            (mod.Name.toLowerCase().includes(
                              translate('cardPage.modRemove').toLowerCase()
                            ) ||
                              mod.Name.toLowerCase().includes(
                                translate('cardPage.modWithout').toLowerCase()
                              )) &&
                            modsByCategory[mod.CategoryID][mod.ID] > 0
                          "
                          class="v-constructor-category-mod-img-icon-ban"
                          :onclick="() => clickOnBadge(category, mod)"
                        />
                        <icon-general-check
                          v-else-if="modsByCategory[mod.CategoryID][mod.ID] > 0"
                          class="v-constructor-category-mod-img-icon"
                          :onclick="() => clickOnBadge(category, mod)"
                        />
                      </div>
                    </div>
                    <label
                      class="v-constructor-mod-name"
                      :for="mod.ID"
                    >
                      <span class="v-checkbox" />
                      <span v-html="mod.Name" />
                    </label>
                    <div class="v-mod-price-block">
                      <span
                        v-if="mod.Price > 0"
                        class="v-mod-buble"
                      >
                        + <common-currency :amount="mod.Price"
                      /></span>
                    </div>
                  </template>

                  <div class="v-mod-counter-block">
                    <!--#region select-->
                    <select
                      v-if="appConfig.VueSettingsPreRun.ConstructorPageModsType === 'select'"
                      :id="mod.ID"
                      class="v-constructor-category-mod-select v-form-control v-w-320"
                      :aria-label="mod.Name"
                      data-test-id="product-constructor-category-mod-select"
                      v-model="modsByCategory[mod.CategoryID][mod.ID]"
                    >
                      <option
                        v-for="val in Array.from(
                          Array(
                            Math.max(
                              //create array 0...N where N is:
                              (mod.ValueMax === 0 //if mod has its own limit we will select the lowest value
                                ? numberOfAvailableModsByCategory[mod.CategoryID]
                                : Math.min(
                                    mod.ValueMax,
                                    numberOfAvailableModsByCategory[mod.CategoryID]
                                  )) + 1, //number that is left for current category
                              modsByCategory[mod.CategoryID][mod.ID] + 1 //number for current mod
                            )
                            //Why? If maximum is 5 and user selects 3 for one mod, it leaves 2 for rest of category
                            //But 3 must be still available for current category
                            //So we check what is max of 3 and 2 and leave 3 for selected mod and give 2 for rest of category
                          ).keys()
                        )"
                        :key="`constructor-variant-${category.ID}-mod-${mod.ID}-val-${val}`"
                        :value="val"
                        data-test-id="product-constructor-category-mod-select__option"
                      >
                        {{ val }}
                      </option>
                    </select>
                    <!--#endregion -->
                    <!--#region arithmetic-->
                    <arora-stepper
                      v-else-if="appConfig.VueSettingsPreRun.ConstructorPageModsType === 'arithmetic'"
                      :disable-plus-button="
                        modsByCategory[mod.CategoryID][mod.ID] >= mod.ValueMax ||
                        numberOfAvailableModsByCategory[mod.CategoryID] <= 0
                      "
                      :disabled="
                        numberOfAvailableModsByCategory[mod.CategoryID] <= 0 &&
                        modsByCategory[mod.CategoryID][mod.ID] === 0
                      "
                      :data-test-id="`product-constructor-stepper-${mod.ID}`"
                      :label="translate('addToCartButton.addToCart')"
                      :on-decrement="() => updateModCount(mod.CategoryID, mod.ID, false)"
                      :on-increment="() => updateModCount(mod.CategoryID, mod.ID, true)"
                      :show-minus-on-zero="appConfig.VueSettingsPreRun.ConstructorPageModsShowMinus"
                      :value="modsByCategory[mod.CategoryID][mod.ID]"
                    />
                    <!--#endregion -->

                    <!--#region input-->
                    <div
                      v-else-if="appConfig.VueSettingsPreRun.ConstructorPageModsType === 'input'"
                      v-form-validator="{
                        Form: 'menu',
                        Value: modsByCategory[mod.CategoryID][mod.ID],
                        Required: true,
                        Validations: ['digits', 'intMinMax'],
                        MaxLength: inputMax(mod),
                        MinLength: mod.ValueMin
                      }"
                    >
                      <input
                        class="v-constructor-category-mod-select v-form-control v-w-320"
                        :disabled="
                          modsByCategory[mod.CategoryID][mod.ID] > 0 &&
                          (inputMax(mod) === 0 ||
                            (numberOfAvailableModsByCategory[mod.CategoryID] <= 0 &&
                              modsByCategory[mod.CategoryID][mod.ID] === 0))
                        "
                        :aria-label="mod.Name"
                        :aria-valuemax="inputMax(mod)"
                        :aria-valuemin="mod.ValueMin"
                        :maxlength="2"
                        data-test-id="product-constructor-category-mod-input"
                        type="number"
                        v-model="modsByCategory[mod.CategoryID][mod.ID]"
                      />
                    </div>
                  </div>
                </div>
                <!--#endregion -->
              </div>
              <!--#endregion -->
            </div>
          </div>
        </template>
        <span
          v-else
          v-html="translate('cardPage.unavailableMessage')"
        />
      </div>
      <div
        v-if="menuConstructor"
        class="v-constructor-bottom"
      >
        <div
          class="v-constructor-bottom-prices"
          :class="{
            'v-constructor-bottom-prices-end':
              appConfig.VueSettingsPreRun.ConstructorPageShowPriceOnButton
          }"
        >
          <div
            v-if="menuConstructor.InStopList"
            class="v-mr-sm v-warning-color v-mb-sm"
            v-html="translate('cardPage.unavailableMessage')"
          />
          <transition
            appear
            mode="out-in"
            name="fade"
          >
            <div
              v-if="modsError || numError"
              class="v-error"
            >
              {{ modsError ?? numError }}
            </div>
          </transition>
          <template v-if="!appConfig.VueSettingsPreRun.ConstructorPageShowPriceOnButton">
            <div class="v-constructor-pay">
              <template v-if="discountApplicable">
                <span v-html="translate('cardPage.totalPay')" />
                <common-currency
                  strikethrough
                  :amount="totalPay"
                />
                <span v-html="translate('cardPage.totalPayWithDiscount')" />
                <common-currency :amount="(constructorInfo?.Price ?? 0) + totalMods" />
              </template>
              <template v-else>
                <span v-html="translate('cardPage.totalPay')" />
                <common-currency :amount="totalPay" />
              </template>
            </div>
            <div
              v-if="appConfig.VueSettingsPreRun.ConstructorPageShowPriceOfMods && totalMods > 0"
              class="v-constructor-pay"
            >
              <span v-html="translate('cardPage.totalMods')" />
              <common-currency :amount="totalMods" />
            </div>
          </template>

          <arora-stepper
            v-if="
              appConfig.VueSettingsPreRun.ConstructorPageModsShowCount && !menuConstructor.InStopList
            "
            :data-test-id="`consturctor-page-${selectedOption.ID}`"
            :label="translate('addToCartButton.addToCart')"
            :minimum-value="1"
            :on-decrement="onCountDecrement"
            :on-increment="onCountIncrement"
            :show-minus-on-zero="appConfig.VueSettingsPreRun.ConstructorPageModsShowMinus"
            :value="count"
          />
          <!-- todo: решить что то с двойными кнопками -->
          <arora-button
            class-name="v-btn v-btn-primary v-btn-double v-ml-sm"
            ignore-settings
            :disabled="
              !menuConstructor.Active || modsError !== null || isProductUnavailable(menuConstructor)
            "
            :label="translate('addToCartButton.addToCart')"
            data-test-id="product-constructor-add-to-cart"
            @click="async () => await addToBasket()"
          >
            <span
              class="v-btn-double-text"
              v-html="translate('addToCartButton.addToCart')"
            />

            <span
              v-if="appConfig.VueSettingsPreRun.ConstructorPageShowPriceOnButton"
              class="v-btn-right-part"
            >
              <template v-if="discountApplicable">
                <common-currency
                  strikethrough
                  :amount="totalPay"
                />
                <common-currency :amount="(constructorInfo?.Price ?? 0) + totalMods" />
              </template>
              <template v-else>
                <common-currency :amount="totalPay" />
              </template>
            </span>
          </arora-button>
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="v-constructor"
    :class="{ 'v-constructor-full-page-view': !fromPopup }"
  >
    <common-skeleton :loaders-in-row="2" />
  </div>
</template>

<script lang="ts">
import {
  ConstructorRequiredSettings,
  FirstAddType,
  Guid,
  ModWithNoPic,
  ProductDescriptionType,
  ProductMeasureType
} from '~api/consts'

import type { CartItem, ModifierCounts, ModsByCategory } from '~types/clientStore'
import type { ProductCommon } from '~types/common'
import type {
  Category,
  ConstructorCategoryModifier,
  CurrentDiscount,
  Option,
  ProductConstructor,
  Tag
} from '~types/menuStore'

import { type GUID, useCommon, useWindowSize, vFormValidator } from '@arora/common'

export default defineComponent({
  name: 'MenuConstructor',
  components: {},
  directives: {
    formValidator: vFormValidator
  },
  props: {
    constructorId: {
      type: String,
      default: Guid.Empty
    }
  },
  setup() {
    const { translate, numeric, sanitize } = useI18nSanitized()
    const restaurantStore = useRestaurantStore()
    const accountStore = useAccountStore()
    const menuStore = useMenuStore()
    const clientStore = useClientStore()
    const popupStore = usePopupStore()

    const { objectKeys } = useCommon()
    const { fromMillisInZone } = useDateTime()
    const { addToCart } = useCartFunctionsWithDialogs()
    const { isExtraSmall } = useWindowSize()
    const appConfig = useAppConfig()

    const menuConstructor = ref<ProductConstructor | undefined>()
    const selectedOption = ref<Option | undefined>(undefined)
    const modsError = ref<string | null>(null)
    const numberError = ref<string | null>(null)
    const count = ref<number>(1)
    const discount = ref<CurrentDiscount | undefined>(undefined)
    const totalMods = ref<number>(0)
    const totalPay = ref<number>(0)
    const selectedModsByCategory = ref<ModifierCounts>({})
    const threshold = computed<number>(() => appConfig.VueSettingsPreRun.ConstructorPageOptionThreshold)
    const { fromPopup } = useInstance()
    const { setProductModifiersToZero, isProductUnavailable } = useProduct()

    const modsByCategory = ref<ModsByCategory>({})

    return {
      appConfig,
      translate,
      numeric,
      sanitize,
      restaurantStore,
      accountStore,
      menuStore,
      clientStore,
      popupStore,
      addToCart,
      count,
      discount,
      fromMillisInZone,
      fromPopup,
      isExtraSmall,
      isProductUnavailable,
      menuConstructor,
      modsByCategory,
      modsError,
      numError: numberError,
      objectKeys,
      selectedModsByCategory,
      selectedOption,
      setProductModifiersToZero,
      threshold,
      totalMods,
      totalPay
    }
  },
  //TODO: rewriting in composition API doesn't make sense because we will remove it
  /* eslint-disable max-lines */
  // eslint-disable-next-line vue/component-api-style
  computed: {
    ProductDescriptionType() {
      return ProductDescriptionType
    },
    ProductMeasureType() {
      return ProductMeasureType
    },
    isTextDescription(): boolean {
      return !(
        this.menuConstructor?.DescriptionType === ProductDescriptionType.Ingredients ||
        this.menuConstructor?.DescriptionType === ProductDescriptionType.RemovableIngredients
      )
    },
    descriptionWithOptionsSanitized(): string {
      return this.sanitize(
        (this.selectedOption?.ProductDescription
          ? this.selectedOption?.ProductDescription
          : this.constructorInfo?.Description) ?? ''
      )
    },
    small(): boolean {
      const filledCategories =
        this.menuConstructor?.Categories?.filter((cat: Category) => {
          return cat.ConstructorCategoryModifiers.length > 0
        }) ?? []

      return filledCategories.length === 0
    },
    showImage(): boolean {
      const tags = this.menuConstructor?.Tags ?? []
      if (tags.length > 0) return true

      return tags.every((tag: Tag) => {
        return tag.LabelKey !== ModWithNoPic
      })
    },
    constructorInfo(): ProductCommon | undefined {
      return this.discount && this.discountApplicable
        ? this.discount.ActionProductData
        : this.menuConstructor
    },
    constructorCategories(): Category[] {
      const result: Category[] = []
      const recommended = this.menuConstructor?.Recommended

      if (this.appConfig.VueSettingsPreRun.ConstructorPageShowRecommended && recommended) {
        recommended.Name = this.translate('cardPage.recommended')

        result.push(recommended)
      }

      return [...result, ...(this.menuConstructor?.Categories ?? [])]
    },
    numberOfAvailableModsByCategory(): ModifierCounts {
      const result: ModifierCounts = {}

      for (const category of this.menuConstructor?.Categories ?? []) {
        let count = 0
        for (const mod of category.ConstructorCategoryModifiers) {
          if (
            mod.ProductAttachedTo === this.selectedOption?.ID ||
            mod.ProductAttachedTo === this.menuConstructor?.ID
          ) {
            count += this.modsByCategory[category.ID][mod.ID]
          }
        }

        if (category.EnableMaxLimit && category.IngredientsMax) {
          result[category.ID] = category.IngredientsMax - count
        } else {
          result[category.ID] = 10 - count
        }
      }

      return result
    },
    modLimitPerCategory(): ModifierCounts {
      const result: ModifierCounts = {}

      for (const category of this.menuConstructor?.Categories ?? []) {
        if (category.EnableMaxLimit) {
          result[category.ID] = category.IngredientsMax ?? 10
        } else {
          result[category.ID] = 10
        }
      }

      return result
    },
    discountApplicable(): boolean {
      if (
        !this.discount ||
        !this.selectedOption ||
        this.appConfig.RestaurantSettingsPreRun.GMT === null ||
        this.discount.NotShowInMenu
      )
        return false

      const expirationDate = this.fromMillisInZone(
        this.discount.TimeEndSecTS * 1000,
        this.appConfig.RestaurantSettingsPreRun.GMT
      )

      let result = expirationDate.diffNow().milliseconds > 0

      if (this.discount.CurrentProductOptionID?.length > 0) {
        result = result && this.discount.CurrentProductOptionID === this.selectedOption.ID
      }

      // eslint-disable max-lines
      for (const category of this.menuConstructor?.Categories ?? []) {
        for (const mod of category.ConstructorCategoryModifiers) {
          if (this.modsByCategory[category.ID][mod.ID] > 0) {
            result = result && this.discount.ActionProductModifiers.includes(mod.ID)
          }
        }
      }
      return result
    }
  },
  //TODO: rewriting in composition API doesn't make sense because we will remove it
  // eslint-disable-next-line vue/component-api-style
  watch: {
    'menuStore.SelectedOptionsPerProduct': {
      handler: function () {
        // Description as ingredients mods set to zero
        this.setProductModifiersToZero(this.constructorId as GUID)

        this.selectedOption = this.menuStore.SelectedOptionsPerProduct.get(this.constructorId as GUID)
        for (const category of this.menuConstructor?.Categories ?? []) {
          for (const mod of category.ConstructorCategoryModifiers) {
            if (this.modsByCategory[category.ID][mod.ID] > 0) {
              this.modsByCategory[category.ID][mod.ID] = 0
            }
          }
        }
        this.recalcPrice()
      },
      deep: true
    },
    modsByCategory: {
      handler: function () {
        this.recalcPrice()
      },
      deep: true
    },
    async 'clientStore.ClientState.data.Cart.Content'(newContent, oldContent) {
      if (oldContent === null) return // after initialization oldContent is null
      if (newContent === null) return // in case of client state update error

      if (newContent.length !== oldContent.length) this.checkForLimit()
    }
  },
  //TODO: rewriting in composition API doesn't make sense because we will remove it
  // eslint-disable-next-line vue/component-api-style
  async mounted(): Promise<void> {
    await this.menuStore.initDiscountTimers()
    this.menuConstructor = this.appConfig.Constructors.find(
      (constructor) => constructor.ID === this.constructorId
    )

    if (!this.menuConstructor) {
      return
    }

    if (!this.menuStore.SelectedModifiersPerProduct.get(this.constructorId as GUID)) {
      this.menuStore.SelectedModifiersPerProduct.set(this.constructorId as GUID, {})
    }

    const optionsKeys = this.menuConstructor.Options ? this.objectKeys(this.menuConstructor.Options) : []
    const allActiveProductOptions: Option[] = []
    if (optionsKeys.length > 0) {
      for (const key of optionsKeys) {
        const optionsByGroup = Object.values(this.menuConstructor?.Options[key as GUID] as Option[])
        const activeOptionsByGroup = optionsByGroup.filter((option: Option) => option.Active)

        allActiveProductOptions.push(...activeOptionsByGroup)
      }

      if (
        this.constructorId &&
        this.menuStore.SelectedOptionsPerProduct.get(this.constructorId as GUID)
      ) {
        this.selectedOption = this.menuStore.SelectedOptionsPerProduct.get(this.constructorId as GUID)
      } else {
        const hasDefaultNonStopListOption = computed<boolean>(() =>
          allActiveProductOptions.some(
            (option: Option) => option.IsDefault && !this.menuStore.OptionsInStopList.includes(option.ID)
          )
        )

        this.selectedOption = allActiveProductOptions.find((option: Option) => {
          return (
            !this.menuStore.OptionsInStopList.includes(option.ID) &&
            (hasDefaultNonStopListOption.value ? option.IsDefault : true)
          )
        })

        if (!this.selectedOption) {
          const firstKey = optionsKeys[0]
          this.selectedOption = this.menuConstructor.Options[firstKey][0]
        }

        this.menuStore.SelectedOptionsPerProduct.set(this.constructorId as GUID, this.selectedOption)
      }
    } else {
      //without options, we take product values
      this.selectedOption = {
        AvailableByOrderType: {
          CourierDelivery: this.menuConstructor.AvailableByOrderType.CourierDelivery,
          NoShipment: this.menuConstructor.AvailableByOrderType.NoShipment,
          InHall: this.menuConstructor.AvailableByOrderType.InHall
        },
        Active: this.menuConstructor.Active,
        Description: '',
        Name: '',
        IsDefault: true,
        Measure: this.menuConstructor.Measure,
        ProductDescription: this.menuConstructor.Description ?? '',
        Quantity: this.menuConstructor.Quantity,
        Title: '',
        ID: this.menuConstructor.ID,
        Price: this.menuConstructor.Price,
        PriceInPoints: this.menuConstructor.PriceInPoints,
        PriceModified: this.menuConstructor.PriceModified,
        Weight: this.menuConstructor.Weight,
        EnergyAmount: this.menuConstructor.EnergyAmount,
        ProteinsAmount: this.menuConstructor.ProteinsAmount,
        FatAmount: this.menuConstructor.FatAmount,
        CarbohydrateAmount: this.menuConstructor.CarbohydrateAmount,
        ModifierGroups: [],
        SWeight: 0,
        MinCountModifiers: 0,
        CalcMinPrice: 0,
        CalcMinPriceModified: 0
      }
    }
    this.clearModsSelected()
    this.checkForLimit()
    this.checkDiscountTimers()
    this.totalPay =
      this.selectedOption?.ID === this.menuConstructor?.ID
        ? (this.menuConstructor?.PriceModified ?? 0)
        : (this.menuConstructor?.PriceModified ?? 0) + (this.selectedOption?.PriceModified ?? 0)

    if (this.discount) {
      const expirationDate = this.fromMillisInZone(
        this.discount.TimeEndSecTS * 1000,
        this.appConfig.RestaurantSettingsPreRun.GMT
      )

      const result = expirationDate.diffNow().milliseconds > 0
      const option = allActiveProductOptions.find((o) => o.ID === this.discount?.CurrentProductOptionID)

      if (result && option) {
        this.selectedOption = option
        this.menuStore.SelectedOptionsPerProduct.set(this.constructorId as GUID, option)
      }
    }

    this.restaurantStore.Metrics?.SendMetricsProductView(
      this.menuConstructor!.GroupID,
      this.menuConstructor!.ID,
      this.menuConstructor!.Name,
      this.menuConstructor!.Price,
      1,
      this.menuStore.SelectedOptionsPerProduct.get(this.menuConstructor!.ID)?.ID
    )
  },
  //TODO: rewriting in composition API doesn't make sense because we will remove it
  // eslint-disable-next-line vue/component-api-style
  methods: {
    async onCountDecrement(): Promise<void> {
      this.count--
    },
    async onCountIncrement(): Promise<void> {
      this.count++
    },
    recalcPrice(): void {
      this.modsError = null

      this.totalMods = 0

      for (const category of this.menuConstructor?.Categories ?? []) {
        for (const mod of category.ConstructorCategoryModifiers) {
          const count = this.modsByCategory[category.ID][mod.ID]
          if (
            mod.ProductAttachedTo === this.menuConstructor?.ID ||
            mod.ProductAttachedTo === this.selectedOption?.ID
          ) {
            this.totalMods += count * mod.Price
          }
        }
      }

      const totalPayBaseProduct: number =
        this.selectedOption?.ID === this.menuConstructor?.ID
          ? (this.menuConstructor?.PriceModified ?? 0)
          : (this.menuConstructor?.PriceModified ?? 0) + (this.selectedOption?.PriceModified ?? 0)
      this.totalPay = (totalPayBaseProduct + this.totalMods) * this.count

      this.selectedModsByCategory = {}

      for (const category of this.menuConstructor?.Categories ?? []) {
        let count = 0
        for (const mod of category.ConstructorCategoryModifiers) {
          //if attached to option or to product
          if (
            mod.ProductAttachedTo === this.selectedOption?.ID ||
            mod.ProductAttachedTo === this.menuConstructor?.ID
          )
            count += this.modsByCategory[category.ID][mod.ID]
        }

        this.selectedModsByCategory[category.ID] = count
      }
    },
    getAvailableModsPerCategory(category: Category): ConstructorCategoryModifier[] {
      return category.ConstructorCategoryModifiers.filter(
        (m) =>
          m.Active &&
          !m.InStopList &&
          (m.ProductAttachedTo === this.menuConstructor?.ID ||
            m.ProductAttachedTo === this.selectedOption?.ID)
      )
    },
    inputMax(mod: ConstructorCategoryModifier): number {
      return Math.max(
        (mod.ValueMax === 0
          ? this.numberOfAvailableModsByCategory[mod.CategoryID]
          : Math.min(mod.ValueMax, this.numberOfAvailableModsByCategory[mod.CategoryID])) +
          this.modsByCategory[mod.CategoryID][mod.ID],
        0
      )
    },
    checkForLimit(): void {
      if (this.menuConstructor) {
        const id = this.menuConstructor.ID

        const inCartItems =
          this.clientStore.ClientState.data?.Cart?.Content?.filter((item: CartItem) => {
            return item.ProductID === id
          }) ?? []

        let inCart = 0

        for (const item of inCartItems) {
          inCart += item.Count
        }

        if (inCart === this.menuConstructor.MaxInCart) {
          this.numError = this.translate('cardPage.numMax', {
            max: this.menuConstructor.MaxInCart
          })
        } else {
          this.numError = null
        }
      }
    },
    clearModsSelected(): void {
      for (const category of this.menuConstructor?.Categories ?? []) {
        this.modsByCategory[category.ID] = {}

        for (const mod of category.ConstructorCategoryModifiers) {
          this.modsByCategory[category.ID][mod.ID] = mod.ValueDefault
        }
      }
    },
    checkDiscountTimers(): void {
      this.discount = this.menuStore.DiscountTimers?.data?.CurrentDiscounts.find(
        (discount: CurrentDiscount) => {
          return discount.CurrentProductData.ID === this.menuConstructor?.ID
        }
      )

      if (this.discount && this.discount.CurrentProductOptionID?.length > 0) {
        const currentProductOptionID = this.discount.CurrentProductOptionID
        for (const [, value] of Object.entries<Option[]>(this.menuConstructor?.Options ?? [])) {
          const optionInDiscounts = value.find((option: Option) => option.ID === currentProductOptionID)
          if (optionInDiscounts) {
            this.selectedOption = optionInDiscounts
            this.clearModsSelected()
            break
          }
        }
      }
    },
    clickOnBadge(category: Category, mod: ConstructorCategoryModifier): void {
      if (this.categoryOnlyOneMod(category)) {
        this.radioSelect(category, mod)
      } else if (this.modsByCategory[category.ID][mod.ID] === 0) {
        this.modsByCategory[category.ID][mod.ID] = 1
      } else {
        this.modsByCategory[category.ID][mod.ID] = 0
      }
    },
    async updateModCount(categoryId: GUID, modId: GUID, increment: boolean) {
      if (increment) {
        if (
          this.numberOfAvailableModsByCategory[categoryId] > 0 &&
          this.modsByCategory[categoryId][modId] < this.modLimitPerCategory[categoryId]
        )
          this.modsByCategory[categoryId][modId]++
      } else if (this.modsByCategory[categoryId][modId] > 0) {
        this.modsByCategory[categoryId][modId]--
      }
    },
    isCategoryRequired(category: Category) {
      return (
        category.TypeRequire === ConstructorRequiredSettings.OneAndMoreRequired ||
        category.TypeRequire === ConstructorRequiredSettings.OneRequired
      )
    },
    categoryOnlyOneMod(category: Category) {
      return (
        category.TypeRequire === ConstructorRequiredSettings.OneNonRequired ||
        category.TypeRequire === ConstructorRequiredSettings.OneRequired
      )
    },
    categoryOnlyOneRequiredMod(category: Category) {
      return category.TypeRequire === ConstructorRequiredSettings.OneRequired
    },
    setSelectedOption(firstLevel: GUID, optionId: GUID): void {
      this.selectedOption = this.menuConstructor?.Options[firstLevel].find((option: Option) => {
        return option.ID === optionId
      })

      const totalPayBaseProduct: number =
        this.selectedOption?.ID === this.menuConstructor?.ID
          ? (this.menuConstructor?.PriceModified ?? 0)
          : (this.menuConstructor?.PriceModified ?? 0) + (this.selectedOption?.PriceModified ?? 0)
      this.totalPay = totalPayBaseProduct * this.count

      if (!this.small) {
        this.clearModsSelected()
      }
    },
    radioSelect(category: Category, mod: ConstructorCategoryModifier): void {
      for (const mod of category.ConstructorCategoryModifiers) {
        this.modsByCategory[category.ID][mod.ID] = 0
      }

      this.modsByCategory[category.ID][mod.ID] = 1
    },
    checkboxSelect(event: Event, mod: ConstructorCategoryModifier) {
      if (this.modsByCategory[mod.CategoryID][mod.ID] === 0)
        this.modsByCategory[mod.CategoryID][mod.ID] = (event.target as HTMLInputElement).value ? 1 : 0
      else this.modsByCategory[mod.CategoryID][mod.ID] = (event.target as HTMLInputElement).value ? 0 : 1
    },
    async addToBasket(): Promise<void> {
      const modifiers: ModifierCounts = {}

      for (const category of this.menuConstructor?.Categories ?? []) {
        let addedPerCategory = 0
        for (const mod of category.ConstructorCategoryModifiers) {
          if (this.modsByCategory[category.ID][mod.ID] > 0) {
            addedPerCategory += this.modsByCategory[category.ID][mod.ID]
            modifiers[mod.ID] = this.modsByCategory[category.ID][mod.ID]
          }
        }

        switch (category.TypeRequire) {
          case ConstructorRequiredSettings.OneRequired:
            if (addedPerCategory !== 1) {
              this.modsError = this.translate('cardPage.minModsRequirementsNotMetOne', {
                category: category.Name
              })
            }
            break
          case ConstructorRequiredSettings.OneAndMoreRequired:
            if (addedPerCategory < 1) {
              this.modsError = this.translate('cardPage.minModsRequirementsNotMetOne', {
                category: category.Name
              })
            }
            break
          default:
            if (category.IngredientsMin && addedPerCategory < category.IngredientsMin) {
              this.modsError = this.translate(
                category.IngredientsMin < 5
                  ? 'cardPage.minModsRequirementsNotMetFromTwoToFour'
                  : 'cardPage.minModsRequirementsNotMet',
                {
                  category: category.Name,
                  min: category.IngredientsMin
                }
              )
            }
            break
        }
      }

      const mods = this.menuStore.SelectedModifiersPerProduct.get(this.constructorId as GUID)

      if (mods)
        for (const modID of this.objectKeys(mods)) {
          const modCount = mods[modID]
          if (modCount > 0) {
            modifiers[modID] = modCount
          }
        }

      if (this.selectedOption && !this.modsError) {
        const added = await this.addToCart(
          this.discount && this.discountApplicable
            ? this.discount.ActionProductData.ID
            : (this.constructorId as GUID),
          FirstAddType.Default,
          this.menuConstructor!.GroupID,
          this.selectedOption.Name,
          this.selectedOption.Price,
          this.menuStore.SelectedOptionsPerProduct.get(this.constructorId as GUID)?.ID,
          modifiers,
          this.count,
          this.discount && this.discountApplicable ? this.discount.ID : null
        )
        if (added) this.setProductModifiersToZero(this.constructorId as GUID)

        if (added && this.fromPopup) {
          this.popupStore.closePopup()
        }
      }
    },
    isModDisable(category: Category, mod: ConstructorCategoryModifier): boolean {
      return (
        !this.categoryOnlyOneMod(category) &&
        category.EnableMaxLimit &&
        this.numberOfAvailableModsByCategory[mod.CategoryID] === 0 &&
        this.modsByCategory[mod.CategoryID][mod.ID] === 0
      )
    }
  }
})
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-constructor {
  color: variables.$BodyTextColor;
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.v-constructor-options {
  .v-options-container {
    &.toggle {
      .v-option-wrapper {
        flex: 1 1 auto;
      }
    }
  }

  &.v-dark {
    .toggle {
      background: #333;
      color: #fff;
    }

    input:checked + label {
      background: #595959;
      color: #fff;
    }
  }

  input:checked + label {
    pointer-events: none;
  }
}

.v-constructor-take-half {
  .v-constructor-no-right {
    flex: 0 0 100%;
    max-width: 100%;

    padding-left: 1rem;
    padding-right: 1rem;
  }

  .v-constructor-left-part {
    flex: 0 0 50%;
    max-width: 50%;

    padding-left: 1rem;
    padding-right: 1rem;
  }

  .v-constructor-right-part {
    flex: 0 0 50%;
    max-width: 50%;

    padding-left: 1rem;
    padding-right: 1rem;
  }

  @include mixins.sm {
    flex-direction: column;

    .v-constructor-left-part {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
    }
    .v-constructor-right-part {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
    }
  }
}

.v-constructor-square-photo {
  .v-constructor-no-right {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .v-constructor-left-part {
    flex: 0 0 35%;
    max-width: 35%;

    padding-left: 1rem;
    padding-right: 1rem;
  }

  .v-constructor-right-part {
    flex: 0 0 65%;
    max-width: 65%;

    padding-left: 1rem;
    padding-right: 1rem;
  }

  @include mixins.sm {
    flex-direction: column;

    .v-constructor-left-part {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .v-constructor-right-part {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

.v-constructor-personal-product-message {
  color: variables.$ErrorColor;
  margin-bottom: 10px;

  svg {
    width: 0.9rem;
    height: 0.9rem;
  }
}

.v-constructor-full-page-view {
  &.v-constructor-full-page-view-no-mod {
    max-width: 600px;
    margin: 0 auto;
  }

  .v-scroll-box {
    background: variables.$BodyElementsBackground;
    min-height: 250px;
  }
}

.v-constructor-name {
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 10px;
  margin-right: 10px;
}

.v-mod-box-shadow {
  box-shadow: 0 5px 14px 2px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  padding: 10px;

  &.v-dark {
    box-shadow: 0 5px 14px 2px #6e6e6e30;
  }
}

.v-help-block-icon {
  margin-left: 5px;
  position: relative;
  cursor: pointer;
}

.v-mod-box-shadow {
  width: 100%;

  .v-help-block-icon {
  }
}

.v-constructor-right-part {
  .v-constructor-category-mod {
    .shop-counter {
      width: 75%;

      input {
        margin-bottom: 0;
      }

      .count-to-basket {
        text-align: center;
      }
    }

    .v-image-label {
      position: relative;
    }

    .v-constructor-category-mod-img {
      flex: 0 0 100%;
      max-width: 100%;
      border-radius: variables.$BorderRadius;
    }

    .v-constructor-category-mod-name {
      display: flex;
      height: 100%;
      width: 100%;

      .v-input-radio,
      .v-input-checkbox {
        display: none;

        + label {
          flex: 0 0 100%;
          max-width: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          border: variables.$BorderWidth solid variables.$BorderColor;
          border-radius: 10px;
          box-shadow: variables.$InputShadow;
          height: 100%;
          padding: 15px;
          position: relative;
        }

        &:checked {
          & + label {
            border: variables.$BorderWidth solid variables.$PrimaryBackgroundColor;

            &:before {
              content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDExIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGlkPSJQYXRoIiBkPSJNMTAuNjY2NyAxLjI0NDQ0TDkuNDIyMjIgMEwzLjU1NTU2IDUuODY2NjdMMS4yNDQ0NCAzLjU1NTU2TDAgNC44TDMuNTU1NTYgOC4zNTU1NkwxMC42NjY3IDEuMjQ0NDRaIiBmaWxsPSJjdXJyZW50Q29sb3IiLz4KPC9zdmc+Cg==);
              color: variables.$PrimaryBackgroundColor;
              position: absolute;
              top: 15px;
              right: 15px;
              z-index: 2;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

.v-constructor-bottom {
  flex: 0 0 100%;
  max-width: 100%;
  padding-top: 15px;
}

.v-constructor-bottom-prices {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  &.v-constructor-bottom-prices-end {
    justify-content: flex-end;
  }

  .v-error {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.v-constructor-category-name {
  font-size: 15px;
  font-weight: bold;

  .v-constructor-category-mod-counter {
    flex-wrap: nowrap;
    display: flex;
    flex: 0 0 auto;
  }
}

.v-constructor-category-mods-wrapper {
  display: flex;
  flex-flow: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.v-constructor-category-mod {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;

  .v-badge-arithmetic {
    width: 35px;
    height: 35px;
    transform: scale(1) translate(0);
  }

  .v-constructor-category-mod-title {
    text-align: center;
    font-size: variables.$TextSizeMain;
  }

  label {
    justify-content: center;

    .v-constructor-category-mod-img {
      max-width: 100%;
      height: auto;
      margin-bottom: 10px;
    }
  }
}

.v-constructor-category-mod-three-columns {
  flex: 0 0 33.33%;
  max-width: 33.33%;

  @include mixins.md {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @include mixins.sm {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .v-mod-price-block {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;

    margin-bottom: 1rem;
  }
}

.v-constructor-category-mod-columns {
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .v-badge-root {
    display: flex;
    align-items: center;
  }

  .v-image-label {
    flex: 0 0 15%;
    max-width: 15%;

    .v-constructor-category-mod-img-icon-ban {
      width: 4rem;
      height: 4rem;
      fill: none;
    }
  }

  .v-constructor-category-mod-title {
    flex: 0 0 auto;
    max-width: 85%;
    text-align: left;
    padding-left: 15px;
  }

  .v-badge-arithmetic {
    position: relative;
    flex: 0 0 auto;
    margin-left: 8px;
  }
}

.v-mod-bubble {
  background: variables.$PrimaryBackgroundColor;
  color: variables.$PrimaryColor;
  font-size: variables.$TextSizeMain;
  border-radius: variables.$BorderRadiusInput;
  padding: 3px 6px;
  display: inline-block;
  white-space: nowrap;
  display: flex;
}

.v-constructor-category-mod-two-columns {
  flex: 0 0 50%;
  max-width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .v-pic-two-columns {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 0 0 100%;

    & + .v-mod-counter-block {
      display: none;
    }

    .v-constructor-category-mod-img-big {
      flex: 0 0 60%;
      max-width: 60%;
    }

    .v-constructor-category-mod-title {
      flex: 0 0 40%;
      max-width: 40%;
    }

    .shop-counter {
      width: 100%;
    }
  }

  .v-badge-root {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .v-mod-bubble {
      position: absolute;
      left: -10px;
      bottom: -10px;
    }
  }

  .v-image-label {
    flex: 0 0 25%;
    max-width: 25%;

    .v-constructor-category-mod-img-icon-ban {
      width: 4rem;
      height: 4rem;
      fill: none;
    }
  }

  .v-constructor-mod-name {
    flex: 0 0 75%;
    max-width: 75%;
    white-space: pre-wrap;
    display: inline-flex;
    justify-content: flex-start;
    margin-bottom: 0;
    margin-right: 0;

    span {
      padding-left: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .v-mod-counter-block,
  .v-mod-price-block {
    margin-top: 10px;
  }

  .v-mod-counter-block {
    flex: 0 0 50%;
    max-width: 50%;
    display: inline-flex;
    justify-content: flex-end;
  }

  .v-mod-price-block {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .v-constructor-category-mod-title {
    flex: 0 0 60%;
    max-width: 60%;
    text-align: left;
    padding-left: 15px;
  }

  .v-badge-arithmetic {
    position: relative;
    flex: 0 0 auto;
    margin-left: 8px;
    border-radius: variables.$BorderRadiusInput;
  }

  @include mixins.lg {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.v-constructor-category-mod-four-columns {
  flex: 0 0 25%;
  max-width: 25%;

  .v-mod-box-shadow {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .v-badge-root {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .v-image-label {
    .v-constructor-category-mod-img-icon-ban {
      width: 4rem;
      height: 4rem;
      fill: none;
    }

    margin-bottom: 1rem;
  }

  .v-constructor-category-mod-title {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
  }

  .v-mod-name {
    flex-grow: 1;
  }

  .v-mod-bubble {
    max-width: fit-content;
  }

  .v-mod-price-block {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;

    margin-bottom: 1rem;
  }

  .v-mod-counter-block {
    flex: 0 0 100%;
    max-width: 100%;
  }

  @include mixins.md {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.v-constructor-category-mod-row {
  flex: 0 auto;
}

.v-constructor-category-mod-img-big {
  cursor: pointer;
}

.v-constructor-category-mod-img-big-selected {
  filter: brightness(85%);

  transition: all 0.5s ease;
}

.v-constructor-category-mod-img-icon {
  cursor: pointer;
  position: absolute;
  font-size: 50px;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  opacity: 0.85;
  user-select: none;
  font-style: normal;
}

.v-constructor-category-mod-img-icon-ban {
  cursor: pointer;
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: variables.$ErrorColor;
  opacity: 0.85;
}

.v-constructor-pay {
  font-size: 16px;
  display: flex;
  align-items: center;
}

.v-constructor-category-mod-select {
  width: 100%;
  margin-right: 8px;
}

.v-constructor-image-block {
  position: relative;
}
</style>
